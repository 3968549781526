import React from "react";
import { ImCheckmark2 } from "react-icons/im";

const pricingPlans = [
  {
    name: "Basic",
    price: "$800+",
    description: "Perfect for small businesses or personal projects.",
    features: ["5 pages", "Mobile-friendly", "Basic SEO"],
    buttonText: "Get Started",
  },
  {
    name: "Pro",
    price: "$1,500+",
    description: "Ideal for growing businesses needing custom solutions.",
    features: ["10+ pages", "CMS integration", "Contact forms"],
    buttonText: "Choose Pro",
    highlight: true, // Added flag to highlight this plan
  },
  {
    name: "Enterprise",
    price: "Custom Quote",
    description: "For complex, large-scale projects with advanced features.",
    features: ["E-commerce", "API integrations", "Custom development"],
    buttonText: "Contact Us",
  },
];

const PricingPlans = () => {
  return (
    <div className="py-10 flex flex-col justify-center items-center bg-gray-100 dark:bg-gray-900 p-6">
      <h2 className="text-3xl font-bold text-gray-800 dark:text-white mb-8">
        Choose Your Plan
      </h2>
      <div className="grid md:grid-cols-3 gap-6">
        {pricingPlans.map((plan, index) => (
          <div
            key={index}
            className={`p-6 rounded-2xl shadow-lg border transition-transform hover:scale-105 ${
              plan.highlight
                ? "bg-gradient-to-r from-red-500 to-red-700  text-white border-orange-700 scale-105 shadow-xl"
                : "bg-white dark:bg-gray-800 border-gray-300 dark:border-gray-700"
            }`}
          >
            <h3
              className={`text-3xl font-semibold ${
                plan.highlight ? "text-white" : "text-gray-900 dark:text-white"
              }`}
            >
              {plan.name}
            </h3>
            <p
              className={`my-2 ${
                plan.highlight ? "text-white opacity-90" : "text-gray-600 dark:text-gray-400"
              }`}
            >
              {plan.description}
            </p>
            <p
              className={`text-3xl font-bold my-4 ${
                plan.highlight ? "text-white" : "text-gray-900 dark:text-white"
              }`}
            >
              {plan.price}
            </p>
            <ul className={`space-y-2 ${plan.highlight ? "text-white" : "text-gray-600 dark:text-gray-300"}`}>
              {plan.features.map((feature, i) => (
                <li key={i} className="flex items-center space-x-2">
                  <ImCheckmark2 className={plan.highlight ? "text-white" : "text-green-800"} />
                  <p>{feature}</p>
                </li>
              ))}
            </ul>
            <button
              className={`mt-6 w-full py-2 rounded-lg transition ${
                plan.highlight
                  ? "bg-white text-orange-600 hover:bg-gray-200"
                  : "bg-gradient-to-r from-red-500 to-red-700  text-white hover:bg-orange-700"
              }`}
            >
              {plan.buttonText}
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PricingPlans;
