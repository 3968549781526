import React, { useState } from "react";
import { Helmet } from "react-helmet-async";
import { motion, AnimatePresence } from "framer-motion";
import sushi from '../assets/sushi.png'
import AAhandyman from '../assets/aahandyyman.png'
import lawyer from '../assets/lawyer.png'
import premiumui from '../assets/premiumuikit.png'
import ai from '../assets/aianalytics.png'
import nature from '../assets/nature.png'
import earlcarter from '../assets/earlcarterlaw.png'

const projects = [
  {
    image: sushi,
    title: "Sushi Restaurant Website",
    description: "A beautifully designed website for a sushi restaurant, showcasing the menu and ambiance.",
    link: "https://www.freshsushibobatea.com/"
  },
  {
    image: earlcarter,
    title: "Law Firm Website",
    description: "A modern and professional website designed for a law firm to attract clients in California.",
    link: "https://earlcarter-associates.vercel.app/"
  },
  {
    image: lawyer,
    title: "Law Firm Website",
    description: "A modern and professional website designed for a law firm to attract clients.",
    link: "https://americanlegal.vercel.app/"
  },
  {
    image: premiumui,
    title: "Premium UI Kit",
    description: "A versatile and user-friendly UI kit for designing web applications.",
    link: "https://pageflows.vercel.app/"
  },
  {
    image: ai,
    title: "AI Analytics Dashboard",
    description: "An AI-powered analytics dashboard for monitoring key business metrics.",
    link: "https://analyticai.vercel.app/?page=1"
  },
  {
    image: nature,
    title: "Nature Exploration App",
    description: "An app designed for nature lovers, with maps, trails, and information about flora and fauna.",
    link: "https://photography-xi-red.vercel.app/"
  },
  {
    image: AAhandyman,
    title: "AAHandyman Services",
    description: "AAHandyman provides all sorts of services for your house.",
    link: "https://www.aahandymanllc.com/"
  }
];

export const MoreWork = () => {
  const [activeCategory, setActiveCategory] = useState("All");

  // Categories (you can also group projects by their type if needed)
  const categories = ["All", ...new Set(projects.map((project) => project.title))];

  const filteredProjects =
    activeCategory === "All"
      ? projects
      : projects.filter((project) => project.title === activeCategory);

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: { staggerChildren: 0.2 },
    },
  };

  const itemVariants = {
    hidden: { opacity: 0, scale: 0.9 },
    visible: { opacity: 1, scale: 1 },
    exit: { opacity: 0, scale: 0.9 },
  };

  return (
    <div className="mx-auto max-w-screen-xl px-6 py-16">
      <Helmet>
        <title>Our Work | More Projects</title>
      </Helmet>

      {/* Category Filter */}
      <div className="flex space-x-8 mb-16 overflow-x-auto pb-4">
        {categories.map((category) => (
          <button
            key={category}
            onClick={() => setActiveCategory(category)}
            className={`text-lg font-semibold px-8 py-3 shrink-0 rounded-full transition duration-300 ease-in-out hover:text-white ${
              activeCategory === category
                ? "bg-gradient-to-r from-red-500 to-red-700 text-white"
                : "text-gray-700 border border-gray-300"
            }`}
          >
            {category}
          </button>
        ))}
      </div>

      {/* Projects List */}
      <motion.div
        className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4"
        variants={containerVariants}
        initial="hidden"
        animate="visible"
        exit="hidden"
      >
        <AnimatePresence>
          {filteredProjects.map((project, index) => (
            <motion.div
              key={index}
              variants={itemVariants}
              exit="exit"
              className="rounded-xl shadow-xl transform hover:scale-105 hover:shadow-2xl transition-all duration-500 ease-in-out"
            >
              <div className="relative group">
                <img
                  src={project.image}
                  alt={project.title}
                  className="w-full h-80 object-cover rounded-t-xl transition-all duration-500 ease-in-out group-hover:opacity-80"
                />
                <div className="absolute top-0 left-0 right-0 bottom-0 bg-black bg-opacity-60 flex justify-center items-center opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                  <a
                    href={project.link}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="bg-gradient-to-r from-red-500 to-red-700 px-6 py-3 rounded-lg text-lg font-semibold text-white transition-all duration-300 ease-in-out hover:bg-gradient-to-l hover:from-red-600 hover:to-red-800"
                  >
                    View Project
                  </a>
                </div>
              </div>
              <div className="p-6">
                <h3 className="text-2xl font-semibold text-gray-900">{project.title}</h3>
                <p className="mt-4 text-gray-600">{project.description}</p>
              </div>
            </motion.div>
          ))}
        </AnimatePresence>
      </motion.div>
    </div>
  );
};
