import React, { useState } from "react";
import logo from "../assets/logo.png";
import { CiMenuFries } from "react-icons/ci";
import { MdArrowOutward } from "react-icons/md";
export const Navbar = () => {
  const [showMenu, setShowMenu] = useState(false);

  const handleScroll = (id) => {
    const section = document.getElementById(id);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div className=" text-white">
      <div className="relative flex items-center justify-between pr-4 max-w-7xl mx-auto z-[6000] ">
        <a href="/">
          <img
            className="w-20 md:w-40 md:h-24 cursor-pointer"
            src={logo}
            alt=""
          />
        </a>
        <ul className="hidden md:flex items-center space-x-8 opacity-80 text-[#001D19]">
          <li
            className="cursor-pointer hover:text-gray-200"
            onClick={() => handleScroll("process")}
          >
            Our Process
          </li>
          <li
            className="cursor-pointer hover:text-gray-200"
            onClick={() => handleScroll("work")}
          >
            Our Work
          </li>
          <li
            className="cursor-pointer hover:text-gray-200"
            onClick={() => handleScroll("pricing")}
          >
            Pricing
          </li>
          <li
            className="cursor-pointer hover:text-gray-200"
            onClick={() => handleScroll("faq")}
          >
            FAQ
          </li>
          <li className="cursor-pointer hover:text-gray-200">
            <a href="/blog">Blog</a>
          </li>
        </ul>
        <CiMenuFries
          onClick={() => setShowMenu(!showMenu)}
          className="md:hidden text-2xl cursor-pointer text-black"
        />
        <a
          href="/contact"
          className="hover:bg-white transition-all cursor-pointer duration-300 ease-in-out hover:text-black hidden md:flex items-center space-x-2 bg-[#2b2d2d] p-2 px-4 rounded-full"
        >
          <p>LET'S TALK</p>
          <MdArrowOutward className="bg-gradient-to-r from-red-500 to-red-700  text-black p-2 text-4xl rounded-full" />
        </a>

        <div
          className={
            showMenu
              ? "mobile-menu drop-shadow-md transition-all duration-300 ease-in-out absolute left-0 top-14 text-center lg:hidden bg-white w-full p-4 z-[6000] text-black"
              : "mobile-menu border-b drop-shadow-md transition-all duration-300 ease-in-out absolute top-20 -left-[1200px] text-center lg:hidden bg-white w-full p-4 z-[6000]"
          }
        >
          <ul className=" opacity-80">
            <li
              className="cursor-pointer hover:text-gray-200"
              onClick={() => handleScroll("work")}
            >
              Work
            </li>
          </ul>
          <a href="/blog" className="cursor-pointer hover:text-gray-200">
            <p className="pt-4">Blog</p>
          </a>
          <a
            href="/contact"
            className="hover:bg-white my-4 transition-all cursor-pointer duration-300 ease-in-out hover:text-black flex items-center justify-center w-fit mx-auto space-x-2 bg-[#2b2d2d] p-2 px-4 rounded-full"
          >
            <p className="text-white">LET'S TALK</p>
            <MdArrowOutward className="bg-gradient-to-r from-red-500 to-red-700  text-black p-2 text-4xl rounded-full" />
          </a>
        </div>
      </div>
    </div>
  );
};
