import React, { useState, useEffect, useRef } from "react";

export const HowWeWork = () => {
  const steps = [
    {
      title: "Step 1: Complete Our Questionnaire",
      description:
        "Tell us about your vision, goals, and requirements to help us understand your needs.",
      img: "https://images.pexels.com/photos/3184296/pexels-photo-3184296.jpeg",
    },
    {
      title: "Step 2: We Build and Deploy",
      description:
        "Sit back while we craft and deploy a stunning website tailored to your business.",
      img: "https://images.pexels.com/photos/3184465/pexels-photo-3184465.jpeg",
    },
    {
      title: "Step 3: Maintenance Included",
      description:
        "Enjoy peace of mind knowing we handle all updates and maintenance without extra fees.",
      img: "https://images.pexels.com/photos/3184357/pexels-photo-3184357.jpeg",
    },
  ];

  const [activeStep, setActiveStep] = useState(0);
  const stepRefs = useRef([]);

  useEffect(() => {
    const handleScroll = () => {
      stepRefs.current.forEach((ref, index) => {
        const rect = ref.getBoundingClientRect();
        if (
          rect.top <= window.innerHeight / 2 &&
          rect.bottom >= window.innerHeight / 2
        ) {
          setActiveStep(index);
        }
      });
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <div className="my-12 pt-24 px-4 md:px-10 max-w-7xl mx-auto">
      <h2 className="text-4xl font-bold text-center mb-8">Our Process</h2>
      <p className="text-center text-gray-600 mb-12">
        Starting from <strong>$199</strong> to build and{" "}
        <strong>$150 per month</strong> for maintenance, we provide a complete
        solution to keep your website running smoothly.
      </p>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {steps.map((step, index) => (
          <div
            key={index}
            ref={(el) => (stepRefs.current[index] = el)}
            className={`flex flex-col items-center space-y-4 p-6 rounded-lg  transition-transform duration-300`}
          >
            <div
              className={`w-16 h-16 flex items-center justify-center rounded-full font-bold text-white bg-gradient-to-r from-red-500 to-red-700 `}>
            {index + 1}
             
            </div>
            <h3 className="text-xl font-semibold">{step.title}</h3>
            <img
              src={step.img}
              alt={step.title}
              className="w-full h-48 object-cover rounded-md"
            />
            <p className="text-gray-600">{step.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
};
