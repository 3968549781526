import React, { useState, useEffect } from "react";
import { Hero } from "../components/Hero";
import SwiperComponent from "../components/SwiperComponent";
import { Projects } from "../components/Projects";
import { About } from "../components/About";
import { Experience } from "../components/Experience";
import { Skills } from "../components/Skills";
import { projects, slides } from "../components/data";
import { Process } from "../components/Process";
import { Plans } from "../components/Plans";
import { Faq } from "../components/Faq";
import { FaChevronCircleUp } from "react-icons/fa";
import { Slider } from "../components/Reviews";
import { Clients } from "../components/Clients";
import { HowWeWork } from "../components/HowWeWork";
import ComparisonChart from "../components/comparisonChart";
import { Why } from "../components/Why";
import { MoreWork } from "./MoreWork";
import { OurWork } from "../components/OurWork";
import PricingPlans from "../components/PricingPlans";
import Work from "../components/Work";

export const Home = ({ setDisplayText }) => {
  const [showScrollButton, setShowScrollButton] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setShowScrollButton(true);
      } else {
        setShowScrollButton(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div>
      <Hero />

      {/* <Process /> */}
      <HowWeWork />
      {/* <MoreWork/> */}
      <Work />
      {/* <SwiperComponent items={slides} /> */}
      {/* <Projects setDisplayText={setDisplayText} /> */}
      {/* <OurWork/> */}
      {/* <SwiperComponent items={projects} /> */}
      <About />
      <Clients />
      <PricingPlans />
      <div className="my-10 bg-gray-100 p-4 rounded px-6 md:w-[80%] mx-auto max-w-7xl lg:flex lg:w-[95%] lg:space-x-10">
        <h1 className="font-bold leading-10 text-2xl pt-10 lg:text-4xl lg:w-[800px]">
          We improve demand for efficiency and quality in entrepreneurship with
          creative mind.
        </h1>
        <div className="relative my-10 lg:my-0 mx-auto bg-white md:w-[95%] lg:w-[60%] h-full rounded-xl overflow-hidden">
          <Slider />
        </div>
      </div>
      <Faq />
      {showScrollButton && (
        <div className="transition-all duration-300 ease-linear fixed bottom-10 right-10">
          <FaChevronCircleUp
            className="text-4xl text-orange-800 hover:text-orange-700 cursor-pointer"
            onClick={scrollToTop}
          />
        </div>
      )}
    </div>
  );
};
