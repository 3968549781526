import React, { useState } from "react";
import Slider from "react-slick";
import sushi from '../assets/sushi.png'
import AAhandyman from '../assets/aahandyyman.png'
import lawyer from '../assets/lawyer.png'
import premiumui from '../assets/premiumuikit.png'
import ai from '../assets/aianalytics.png'
import nature from '../assets/nature.png'
import earlcarter from '../assets/earlcarterlaw.png'
import { FaArrowRight } from "react-icons/fa";
const Work = () => {
  const [isHovered, setIsHovered] = useState(false); // Track hover state

  const projects = [
    {
      image: sushi,
      title: "Sushi Restaurant Website",
      description: "A beautifully designed website for a sushi restaurant, showcasing the menu and ambiance.",
      link: "https://www.freshsushibobatea.com/"
    },
    {
      image: earlcarter,
      title: "Law Firm Website",
      description: "A modern and professional website designed for a law firm to attract clients in California.",
      link: "https://earlcarter-associates.vercel.app/"
    },
    {
      image: lawyer,
      title: "Law Firm Website",
      description: "A modern and professional website designed for a law firm to attract clients.",
      link: "https://americanlegal.vercel.app/"
    },
    {
      image: premiumui,
      title: "Premium UI Kit",
      description: "A versatile and user-friendly UI kit for designing web applications.",
      link: "https://pageflows.vercel.app/"
    },
    {
      image: ai,
      title: "AI Analytics Dashboard",
      description: "An AI-powered analytics dashboard for monitoring key business metrics.",
      link: "https://analyticai.vercel.app/?page=1"
    },
    {
      image: nature,
      title: "Nature Exploration App",
      description: "An app designed for nature lovers, with maps, trails, and information about flora and fauna.",
      link: "https://photography-xi-red.vercel.app/"
    },
    {
      image: AAhandyman,
      title: "AAHandyman Services",
      description: "AAHandyman provide all sort of services for your house.",
      link: "https://www.aahandymanllc.com/"
    },
  ];

  const settings = {
    infinite: true,
    speed: 5900,
    slidesToShow: 1.5,  // Default for larger screens
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 0,
    cssEase: "linear",
    arrows: false, // Disable default arrows
    dots: false,
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 1024, // Medium screens (tablets)
        settings: {
          slidesToShow: 1, // Show 1 slide on tablets
        },
      },
      {
        breakpoint: 768, // Smaller screens (phones)
        settings: {
          slidesToShow: 1, // Show 1 slide on phones
        },
      },
    ],
  };

  return (
    <div className=" relative bg-black py-10">
        <h1 className=' py-5 uppercase text-orange-800 text-center'>Quality Work</h1>
        <p className='text-3xl font-extrabold text-center text-white pb-4'>Some of our Finest Work</p>
      <Slider
        {...settings}
        onMouseEnter={() => setIsHovered(true)} // Set hover state to true
        onMouseLeave={() => setIsHovered(false)} // Set hover state to false
      >
        {projects.map((project, index) => (
          <div key={index} className="px-4 m-4">
            <div
              className="relative h-80 md:h-[600px] rounded-lg overflow-hidden"
              style={{
                backgroundImage: `url(${project.image})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            >
              {/* Darkened overlay for text */}
              <div className="absolute bottom-0 left-0 right-0 bg-gradient-to-t from-black to-transparent p-6">
                <div className="text-center text-white">
                  <h3 className="text-3xl font-semibold mb-2">{project.title}</h3>
                  <p className="mb-4">{project.description}</p>
                  <div className="flex justify-center space-x-4">
                    <a
                      href={project.link}
                      target="_blank"
                      rel="noreferrer"
                      className="bg-gradient-to-r text-white from-red-500 to-red-700  py-2 px-6 rounded-full font-semibold hover:bg-yellow-600 transition"
                    >
                      View Project
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </Slider>
      <h1 className='font-bold text-2xl pt-10 text-white text-center'>Take a look at our beautiful work</h1>
<a href='/more-projects' className='w-fit flex  items-center gap-2  mx-auto bg-gradient-to-r from-red-500 to-red-700  text-white rounded-full px-4 py-4 text-xl my-6'>View All Work<FaArrowRight/></a>

      {/* Show arrows when paused */}
      {isHovered && (
        <div className="absolute top-0 left-0 right-0 flex justify-between items-center z-10">
          <div className="prev-arrow cursor-pointer bg-black bg-opacity-50 text-white p-4 rounded-full">
            ←
          </div>
          <div className="next-arrow cursor-pointer bg-black bg-opacity-50 text-white p-4 rounded-full">
            →
          </div>
        </div>
      )}
    </div>
  );
};

export default Work;

