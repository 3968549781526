import React from "react";
import logo from '../../assets/logo.png'
export const LeftSide = () => {
  return (
    <div className="py-6 w-auto">
      <a href="/">
        <img
          className=" w-40"
          src={logo}
          alt=""
        />
      </a>
      <p className="py-4 md:w-3/4">
        Our Motto: "Excellence in service through client-centric solutions."
      </p>
    </div>
  );
};
